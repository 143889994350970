export default class Snake {
	constructor(game) {
		this.game = game;
		
		this.gameWidth = game.gameWidth;
		this.gameHeight = game.gameHeight;
		
		this.properties = {
			width: 3,
			height: 3,
			speed: { 
				x: 0, 
				y: 0 
			},
			accelerate: 5,
			position: { 
				x: 15, 
				y: 50 
			},
			head: document.getElementById('phone'),
			headHeight: 50,
			headWidth: 50,
			score: 0, 
			LEVEL: 1
		};
		
		this.tail = [];
		this.tailSize = 24;
	}
	
	moveRight() {
		this.properties.speed.x = -this.properties.accelerate;
		this.properties.speed.y = 0;
	}
	
	moveLeft() {
		this.properties.speed.x = this.properties.accelerate;
		this.properties.speed.y = 0;
	}
	
	moveUp() {
		this.properties.speed.x = 0;
		this.properties.speed.y = -this.properties.accelerate;
	}
	
	moveDown() {
		this.properties.speed.x = 0;
		this.properties.speed.y = this.properties.accelerate;
	}
	
	draw(ctx) {
		ctx.fillStyle = '#565656';
		ctx.strokeStyle = '#565656';

        if( this.tailSize > 24) this.tail.forEach((object) => {
			ctx.fillRect(object.position.x, object.position.y, this.properties.width, this.properties.height);
			ctx.strokeRect(object.position.x, object.position.y, this.properties.width, this.properties.height);
		});

		ctx.drawImage(this.properties.head, this.properties.position.x, this.properties.position.y - 5, this.properties.headWidth, this.properties.headHeight);
		ctx.fillRect(this.properties.position.x, this.properties.position.y, this.properties.width, this.properties.height);
		ctx.strokeRect(this.properties.position.x, this.properties.position.y, this.properties.width, this.properties.height);
	}
	
	update(deltaTime) {
		if (this.tailSize > this.tail.length) {
	        this.tail.push({
				position: {
					x: this.properties.position.x, 
					y: this.properties.position.y
				}}
			);
		} else {
			this.tail.shift();
	        this.tail.push({
				position: {
					x: this.properties.position.x, 
					y: this.properties.position.y
				}
			});
		}
		
		this.properties.position.x += this.properties.speed.x;
		this.properties.position.y += this.properties.speed.y;
		
		if (this.properties.LEVEL == 3) {
			const canvas = document.querySelector('.game__canvas');

			canvas.setAttribute('style', 'border: 3px solid #ff0000;');
			if (this.properties.position.x > this.gameWidth || this.properties.position.x < 0 || this.properties.position.y > this.gameHeight || this.properties.position.y < 0) {
				this.game.gameOver();
			}
		} else {
			if (this.properties.position.x > this.gameWidth) {
				this.properties.position.x = 0;
			}
			if (this.properties.position.x < 0) {
				this.properties.position.x = this.gameWidth; 
			}
			if (this.properties.position.y > this.gameHeight) {
				this.properties.position.y = 0;
			}
			if (this.properties.position.y < 0) {
				this.properties.position.y = this.gameHeight;
			}
		}
	}
}