export default class Collision {
	constructor(game) {
		this.game = game;
	}
	
	detect_fruit_collision(snake, fruit) {
		let bottomOfFruit = fruit.properties.position.y + fruit.properties.height;
        let leftOfFruit = fruit.properties.position.x;
        let rightOfFruit = fruit.properties.position.x + fruit.properties.width;
        let topOfFruit = fruit.properties.position.y;
        
        let bottomOfSnake = snake.properties.position.y + snake.properties.height;
        let leftOfSnake = snake.properties.position.x;		
        let rightOfSnake = snake.properties.position.x + snake.properties.width;		
        let topOfSnake = snake.properties.position.y;
        
        if (leftOfSnake < rightOfFruit && rightOfSnake > leftOfFruit && topOfSnake < bottomOfFruit && bottomOfSnake > topOfFruit) {
			fruit.nextFruit();
			snake.tailSize++;
			
			if (snake.tailSize == 39) {
				snake.properties.LEVEL = 2;
			}
			if (snake.tailSize == 54) {
				snake.properties.LEVEL = 3;
			}
		    if (snake.properties.LEVEL == 1) {
				snake.properties.score += 10;
			}
		    else if (snake.properties.LEVEL == 2) {
				snake.properties.score += 20;
				snake.properties.accelerate = 6;
			}
		    else if (snake.properties.LEVEL == 3) {
				snake.properties.score += 30;
				snake.properties.accelerate = 7;
			}
		}
	}
	
    detect_tail_collision(snake, tail) {	
		if (snake.properties.position.x === tail.position.x && snake.properties.position.y === tail.position.y && snake.tailSize > 24) {
			this.game.gameOver();
		}
	}
	
	draw(ctx) {
	}
	
	update(deltaTime) {
		this.detect_fruit_collision(this.game.snake, this.game.fruit);

		this.game.snake.tail.forEach((object) => {
			this.detect_tail_collision(this.game.snake, object)
		});
	}
}