/* es-lint disable */
import jump from 'jump.js';
import Game from './game.js';
import ConfettiGenerator from 'confetti-js';
import '@fortawesome/fontawesome-free/css/all.css';

// Get body height and width
const bodyHeight = document.body.scrollHeight;
const bodyWidth = document.body.scrollBody;

// Confetti Canvas
const confettiElement = document.getElementById('confetti');
const confettiSettings = { 
    target: confettiElement,
    max: 70,
    size: 2.5,
    clock: 5,
    props: ['triangle', 'circle'],
    colors: ['159, 177, 240', '211, 159, 240', '150, 205, 146', '255, 187, 15', '240, 174, 159'],
    rotate: true,
    height: bodyHeight,
    width: bodyWidth
};

const confetti = new ConfettiGenerator(confettiSettings);

confetti.render();

// Home Call to Action
const cta = document.querySelector('.header__cta');
const gameSection = document.querySelector('.home__game');

cta.addEventListener('click', () => {
    jump(gameSection, {
        offset: 0,
        duration: 1000
    })
})

// Snake Game Start
let canvas = document.querySelector('.game__canvas');
let ctx = canvas.getContext('2d');

const GAME_WIDTH = 500;
const GAME_HEIGHT = 680;

canvas.height = GAME_HEIGHT;
canvas.width = GAME_WIDTH;

let game = new Game(GAME_WIDTH, GAME_HEIGHT);

// Scoreboard Trigger
const scoreboardTrigger = document.querySelector('.status__cta');

scoreboardTrigger.addEventListener('click', () => {
    const scoreboard = document.querySelector('.game__scoreboard');
    const cta = document.querySelector('.scoreboard__cta');

    scoreboard.classList.add('show');
    cta.style.display = 'none';
})

// Scoreboard Close Trigger
const scoreboardClose = document.querySelector('.scoreboard__heading button');

scoreboardClose.addEventListener('click', () => {
    const scoreboard = document.querySelector('.game__scoreboard');

    scoreboard.classList.remove('show');
})

function updateScores() {
    const inner = document.querySelector('.scoreboard__entries');
    
    // Get the top 10 scores from our scoreboard
    db.collection('scores').orderBy('score', 'desc').limit(10).get().then((snapshot) => {
        let rank = 1;

        snapshot.forEach((doc) => {
            inner.innerHTML += `
                <div class="scoreboard__entry">
                    <div class="scoreboard__left">
                        <p class="rank">${rank++}</p>
                        <p class="name">${(doc.data().name)}</p>
                    </div>
                    <div class="scoreboard__right">
                        <p class="level">${(doc.data().level)}/${doc.data().score}</p>
                    </div>
                </div>
            `;
        })
    })
}

function loop(timestamp) {
	// Define if needed.
	let deltaTime;
	
	ctx.clearRect(0, 0, GAME_WIDTH, GAME_HEIGHT);
    game.update(deltaTime);
	game.draw(ctx);
	
	requestAnimationFrame(loop);
}

updateScores();
requestAnimationFrame(loop);