export default class InputHandler{
	constructor(game){
		this.game = game;
		
		let direction;
		document.addEventListener('keydown', (event) => {
			event.preventDefault();

			let key = event.keyCode || event.which;
			
			if (key == 37 && direction != 'left') {
				direction = 'right'; 
				this.game.snake.moveRight();
			}

			if (key == 38 && direction != 'down') {
				direction = 'up'; 
				this.game.snake.moveUp();
			}

			if (key == 39 && direction != 'right') {
				direction = 'left';
				this.game.snake.moveLeft();
			}

			if (key == 40 && direction != 'up') {
				direction = 'down';
				this.game.snake.moveDown();
			}

			if (key == 37 || 38 || 39 || 40 && direction == 'left' || 'down' || 'right' || 'up') {
				this.game.running();
			}
			
			if (key == 27 && this.game.gameState != this.game.STATE.GAMEOVER) {
				this.game.pause();
			}

			if (key == 32 && this.game.gameState == this.game.STATE.GAMEOVER) {
				location.reload();
			}
		});
		
		// Mobile Keyboard
		document.getElementById('left').addEventListener('click', () => {
			if (direction != 'left') {
				direction = 'right'; 
				this.game.snake.moveRight();
			}
		});
		
		document.getElementById('up').addEventListener('click', () => {
			if (direction != 'down') {
				direction = 'up'; 
				this.game.snake.moveUp();
			}
		});
		
		document.getElementById('right').addEventListener('click', () => {
			if (direction != 'right') {
				direction = 'left'; 
				this.game.snake.moveLeft();
			}
		});
		
		document.getElementById('down').addEventListener('click', () => {
			if (direction != 'up') {
				direction = 'down'; 
				this.game.snake.moveDown();
			}
		});

		const keyboard = document.querySelector('.game__keyboard');
		const buttons = keyboard.getElementsByTagName('button');

		for (let button of buttons) {
			if (button.id != 'space') {
				document.getElementById(button.id).addEventListener('click', () => {
					this.game.running();
				});
			}
		}
		
		document.getElementById('space').addEventListener('click', () => {
			if (this.game.gameState != this.game.STATE.GAMEOVER) {
				this.game.pause();
			} else {
				this.game.running();
			}
		});
	}
}