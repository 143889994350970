// // Create an empty array
let array = [];

// Array counter 
let arrayCounter = 0;

// Get the image ids
const portraits = document.querySelectorAll('.game__portraits img');

// Add them into the empty array
portraits.forEach(element => {
	array.push(element.attributes.id.value)
});
export default class Fruit {
	constructor(game){
		this.game = game;
		
		this.gameWidth = game.gameWidth;
		this.gameHeight = game.gameHeight;
		
		this.properties = {
			width: 70,
			height: 70,
			image: document.getElementById(array[0]),
			position: {
				x: Math.floor(Math.random() * 6.14) * 70, 
				y: Math.floor(Math.random() * 8.71) * 70
			}
		};
	}

	nextFruit() {	
		if (arrayCounter >= 0 && arrayCounter < array.length) {
			arrayCounter++;

			// Reset the counter to 0 once the max array length has been reached
			arrayCounter = (arrayCounter == array.length) ? 0 : arrayCounter;
		}

		this.properties.image = document.getElementById(array[arrayCounter]);
		this.properties.position.x = Math.floor(Math.random() * 6.14) * this.properties.width;
		this.properties.position.y = Math.floor(Math.random() * 8.71) * this.properties.height;
	}
	
	draw(ctx) {		
		if (this.game.snake.properties.LEVEL == 1 || 2 || 3) {
			ctx.drawImage(this.properties.image, this.properties.position.x, this.properties.position.y, this.properties.width, this.properties.height);
		}
	}
		
	update(deltaTime) {
	}
}