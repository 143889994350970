/* es-lint disable */
import Snake from './snake.js';
import InputHandler from './keyInput.js';
import Fruit from './fruit.js';
import Collision from './collision.js';
export default class Game {
	constructor(gameWidth, gameHeight) {
		this.gameWidth = gameWidth;
		this.gameHeight = gameHeight;
		
		this.STATE = {
			PAUSED: 0,
			RUNNING: 1,
			GAMEOVER: 2
		};
		
	    this.snake = new Snake(this);
        this.fruit = new Fruit(this);
		this.collision = new Collision(this);
		new InputHandler(this);
		this.objects = [this.snake, this.fruit, this.collision];
	}
	
	update(deltaTime) {
		if (this.gameState == this.STATE.PAUSED || this.gameState == this.STATE.GAMEOVER) {
			return;
		}
		this.objects.forEach((object) => object.update(deltaTime));
	}
	
	draw(ctx) {
		this.objects.forEach((object) => object.draw(ctx));
		
		ctx.font = '16px Nunito Sans';
		ctx.fillStyle = '#565656';

		const score = document.querySelector('.status__score');
		const level = document.querySelector('.status__level');

		score.innerHTML = `<p>Score: ${this.snake.properties.score}</p>`;
		level.innerHTML = `<p>Level: ${this.snake.properties.LEVEL}</p>`;

		if (this.gameState == this.STATE.PAUSED) {
			ctx.fillStyle = '#96cd92';
			ctx.fillRect(0, 0, this.gameWidth, this.gameHeight);
			ctx.font = 'bold 30px Nunito Sans';
			ctx.fillStyle = '#ffffff';
			ctx.textAlign = 'center';
			ctx.fillText('Paused', this.gameWidth / 2, this.gameHeight / 2);
		}
		
		if (this.gameState == this.STATE.GAMEOVER) {
			// Show scoreboard
			const score = document.querySelector('.game__over-score');
			let gameOver = document.querySelector('.game__over');

			gameOver.classList.add('show');

			score.innerHTML = `
				<p>Level: ${this.snake.properties.LEVEL}</p>
				<p>Your score: ${this.snake.properties.score}</p>
			`;
		}
	}

	running() {
		this.gameState = this.STATE.RUNNING

		const overlay = document.querySelector('.game__overlay');
		overlay.style.display = 'none';
	}
	
	pause() {
		if (this.gameState == this.STATE.PAUSED) {
			this.gameState = this.STATE.RUNNING
		} else {
			this.gameState = this.STATE.PAUSED;
		}
	}
	
	gameOver() {
		this.gameState = this.STATE.GAMEOVER;

		// Hide Scoreboard Trigger when the game is over
		const hideScoreboardTrigger = document.querySelector('.status__cta button');
		hideScoreboardTrigger.style.display = 'none';

		// When the publish button is triggered, ask for the Player's name
		const defaultName = 'YOU';
		const publish = document.querySelector('.scoreboard__publish');

		publish.addEventListener('click', () => {
			let name = window.prompt('Please enter your initials (maximum of 3 characters', defaultName); 

			if (name === null) {
				return null; 
			}

			if (name.length > 0) {
				defaultName == name;
			}

			// Get the first 3 characters of the name string
			const filteredName = name.replace(/[^a-zA-Z ]/g, '').slice(0, 3).toUpperCase();

			// Save name, level, and final score to the database
			let level = this.snake.properties.LEVEL;
			let score = this.snake.properties.score;

			db.collection('scores').doc().set({
				name: filteredName,
				level: level,
				score: score
			})
			.then(function() {
				location.reload();
			})
		});

		// When the play again button is triggered, start a new game 
		const play = document.querySelector('.scoreboard__play');

		play.addEventListener('click', (e) => {
			e.preventDefault();

			location.reload();
		})
	}
}